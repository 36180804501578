import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  s,
  colors,
  sContainer,
  sTitle,
  sImage,
  sGatsbyImage,
  sHtmlList,
  sButtons,
  sContainerPadding,
} from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Button from '../components/Button'
import alt from '../content/alt'
import { useLangContext } from '../context/lang.context'

const content = {
  apply: {
    en: 'Apply',
    es: 'Aplicar',
    ro: 'Aplica',
  },
  offers: {
    en: 'Other offers',
    es: 'Otras ofertas',
    ro: 'Alte oferte',
  },
  agreement: {
    en: `<em>Pursuant to Art. 13 of the general regulation on the protection of personal data of 27 April 2016 (Journal of Laws UE L 119 of 04/05/2016), we would like to inform you that the administrator of your personal data is Omida Logistics Sp. z o.o. al. Grunwaldzka 472, 80-309 Gdańsk. Your personal data will be processed for the purposes of current and future recruitment on the basis of: art. 6 sec. 1 lit. a - consent of the data subject, art. 6 (1) (a) c - legal provision - the Labor Code of June 26, 1974 - general regulation on the protection of personal data of April 27, 2016 (GDPR). Your personal data will be stored for 30 days from the end of the current recruitment. The recipients of your personal data will only be entities authorized to obtain personal data on the basis of legal provisions, as well as processors. You have the right to request the administrator to access personal data, the right to rectify, delete or limit processing, the right to transfer data, the right to withdraw consent at any time. You have the right to lodge a complaint with the supervisory authority. Providing personal data is obligatory under the law and in the remaining scope it is voluntary, but failure to provide data may result in refusal to participate in the recruitment process. Each CV should have the following clause: 'Pursuant to Article 6 (1) (a) and the general regulation on the protection of personal data of 27 April 2016 (Journal of Laws UE L 119 of 04/05/2016), I consent to the processing of my personal data for the purposes of current and future recruitment.</em>`,
    es: `<em>De conformidad con el art. 13 del reglamento general de protección de datos personales de 27 de abril de 2016 (BOE L 119 de 04/05/2016), le informamos que el administrador de sus datos personales es Omida Logistics Sp. zoo. Alabama. Grunwaldzka 472, 80-309 Gdansk. Sus datos personales se procesarán para fines de contratación actual y futura sobre la base de: art. 6 seg. 1 lit. a - consentimiento del interesado, art. 6 (1) (a) c - disposición legal - el Código del Trabajo del 26 de junio de 1974 - reglamento general sobre protección de datos personales del 27 de abril de 2016 (GDPR). Sus datos personales se conservarán durante 30 días a partir de la finalización de la contratación actual. Los destinatarios de sus datos personales serán únicamente las entidades autorizadas para obtener datos personales en base a las disposiciones legales, así como los encargados del tratamiento. Tiene derecho a solicitar al administrador el acceso a los datos personales, derecho a rectificar, eliminar o limitar el procesamiento, derecho a transferir datos, derecho a retirar el consentimiento en cualquier momento. Tiene derecho a presentar una reclamación ante la autoridad de control. El suministro de datos personales es obligatorio según la ley y en el resto del ámbito es voluntario, pero la falta de suministro de datos puede dar lugar a la negativa a participar en el proceso de contratación. Cada CV debe tener la siguiente cláusula: 'De conformidad con el artículo 6 (1) (a) y el reglamento general sobre protección de datos personales de 27 de abril de 2016 (BOE L 119 de 04/05/2016), doy mi consentimiento al tratamiento de mis datos personales con fines de contratación actual y futura.</em>`,
    ro: `<em>În conformitate cu art. 13 din Regulamentul general privind protecția datelor cu caracter personal din 27 aprilie 2016 (Jurnalul de legi UE L 119 din 04/05/2016), dorim să vă informăm că administratorul datelor dumneavoastră cu caracter personal este Omida Logistics Sp. z o.o. al. Grunwaldzka 472, 80-309 Gdańsk. Datele dumneavoastră personale vor fi prelucrate în scopul recrutării curente și viitoare în baza: art. 6 sec. 1 lit. a - consimțământul persoanei vizate, art. 6 (1) (a) c - dispoziție legală - Codul muncii din 26 iunie 1974 - regulament general privind protecția datelor cu caracter personal din 27 aprilie 2016 (GDPR). Datele dumneavoastră personale vor fi stocate timp de 30 de zile de la încheierea recrutării curente. Destinatarii datelor dumneavoastră cu caracter personal vor fi doar entități autorizate să obțină date cu caracter personal în baza prevederilor legale, precum și procesatorii. Aveți dreptul de a solicita administratorului accesarea datelor cu caracter personal, dreptul de a rectifica, șterge sau limita prelucrarea, dreptul de a transfera date, dreptul de a vă retrage consimțământul în orice moment. Aveți dreptul de a depune o plângere la autoritatea de supraveghere. Furnizarea datelor cu caracter personal este obligatorie conform legii, iar în restul domeniului de aplicare este voluntară, însă nefurnizarea datelor poate duce la refuzul de a participa la procesul de recrutare. Fiecare CV ar trebui să aibă următoarea clauză: „În conformitate cu articolul 6 alineatul (1) litera (a) și cu Regulamentul general privind protecția datelor cu caracter personal din 27 aprilie 2016 (Jurnalul de Drept UE L 119 din 04/05/2016), sunt de acord la prelucrarea datelor mele personale în scopul recrutării curente și viitoare.</em>`,
  },
}

export default function JobTemplate({ data }) {
  const { lang } = useLangContext()

  let job = data.jobs.nodes.find((node) => node.lang.slug === lang)
  if (!job) job = data.jobs.nodes[0]

  const { city, title, slug, image, body, description, link } = job

  return (
    <Layout white>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={'career/' + slug}
        noIndex
      />
      <section css={[sContainer, sContainerPadding]}>
        <h1 css={sTitle}>{title}</h1>
        <h2 css={sCity}>{city}</h2>
        <div css={[sImage, { backgroundColor: colors.greyLighter }]}>
          <GatsbyImage
            image={getImage(image)}
            style={sGatsbyImage}
            alt={city + alt}
          />
        </div>
        <article
          css={[sHtmlList, { em: { fontSize: '0.75rem', opacity: 0.6 } }]}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html
              .replaceAll('h1>', 'h2>')
              .concat(content.agreement[lang]),
          }}></article>
        <div css={sButtons}>
          <Button glow link={link} extraCss={{ [s.md]: { width: '60%' } }}>
            {content.apply[lang]}
          </Button>
          <Button reactive link={'/jobs/'}>
            {content.offers[lang]}
          </Button>
        </div>
      </section>
    </Layout>
  )
}

const sCity = {
  fontSize: '1.25rem',
  textTransform: 'uppercase',
  opacity: 0.3,
  // letterSpacing: '0.25em',
  marginBottom: '2rem',
  transform: 'translateY(-50%)',
}

export const query = graphql`
  query ($slug: String!) {
    jobs: allContentfulJob(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        id
        createdAt
        updatedAt
        slug
        city
        link
        title
        description
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
          file {
            url
            fileName
          }
        }
      }
    }
  }
`
